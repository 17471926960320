<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Lightning Protection System (LPS) Support"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="padding: 0px; background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/RollingSphere-min.jpg"
              width="1809"
              height="858"
              alt=""
              title="RollingSphere"
              class="img-responsive wp-image-13074"
              sizes="(max-width: 800px) 100vw, 600px"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS) assists customers with
            determining whether their LPS is designed, installed, tested, and
            maintained adequately. The three main components of an LPS include:
          </p>
          <ul>
            <li>
              Strike interceptor: Air terminals / lightning rods or catenary
              wires
            </li>
            <li>
              Conductor system: Down conductors, wires, building frame, mast,
              etc.
            </li>
            <li>Grounding system: Ground rod(s) or loop (counterpoise)</li>
          </ul>
          <p>
            SMS uses technical documentation including NFPA 780 and UFC 3-575-01
            to compare an existing or planned LPS with current requirements for
            an effective system. SMS also integrates guidance from the Defense
            Ammunition Center (DAC) and DA PAM 385-64 regarding LPS requirements
            for facilities covered under US Army regulations. The risk factors
            for the specific facility location is evaluated in accordance with
            NFPA 780 to determine a quantitative risk level regarding the annual
            threat of strike occurrence.
          </p>
          <p>
            The applicable risk data along with pertinent system information is
            used to evaluate an installed LPS for each building. SMS assesses a
            facility through the following actions:
          </p>
          <ul>
            <li>
              Review design drawings including as-built construction drawings of
              the LPS
            </li>
            <li>Review rolling sphere analysis for representative LPSs</li>
            <li>Review inspection procedures for LPS</li>
            <li>Review inspection records for LPS</li>
            <li>Physically inspect facility protected by LPS</li>
            <li>Physically inspect facility LPS</li>
            <li>
              Review any LPS-related risk assessments, waivers, or Deviation
              Approval and Risk Acceptance Document (DARAD)
            </li>
          </ul>
          <p>
            These evaluations will identify and document compliance or
            deficiencies including the adequacy of surge protection devices
            (SPDs), side flash protection, conductor composition, sizing and
            spacing, bend radii, strike intercept protection, con Any
            deficiencies in an LPS are noted in a checklist for that particular
            building/LPS. All required or implemented mitigation actions to
            bring the LPS into compliance are assembled in an appendix in the
            report. A high-level LPS compliance summary is also provided to
            provide decision makers with the data to make decisions going
            forward based on risk, cost, or level of effort to make each LPS
            compliant. This summary can be customized based on customer
            requirements or priorities.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Lightning Protection System",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS can assess a facility Lightning Protection System (LPS) using technical documentation including NFPA 780, UFC 3-575-01, and DA PAM 385-64."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
